import logo from "./../cjl.png";
const Header = () =>{

	return (

		<header className="container">
			<div className="row justify-content-center mt-3 mb-3">
			<div className="col-8">
			<img id="logo-cjl" src={logo} alt="Caringin Jaya Lestari"/>
			{/* <h1 className="mt-4 p5 text-black rounded">Caringin Jaya Lestari</h1> */}
			<p>PT Caringing Jaya Lestari adalah sebuah perusahaan yang bergerak dibidang penjualan alat-alat listrik dan elektronik. Mulanya kami hanya menjual alat-alat listrik/elektrik yang dimulai sejak 1997, tapi sekitar tahun 2002 kami juga mulai menjual elektronik. Kami berlokasi di Jakarta, Tangerang, Bogor dan Jawa Barat.</p>
			</div>
			</div>
		</header>


	)
}

export default Header;