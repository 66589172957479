const Footer = () =>{

	var today = new Date();

	return (

		<footer>
			<h5>Untuk keterangan lebih lanjut, hubungi kami di</h5>
			<a href="https://wa.me/6285161193456"><img id="wa" src="cjl/WhatsAppButtonGreenLarge.png"/></a>
		</footer>


	)
}

export default Footer;