
const Items = ({items, selectedTag, selectionChange, handleCard}) =>{


	return (
		<>
		<h2>Kami menjual produk dari:</h2>
		<div className="container">
{/* 
		<div className="row justify-content-center mt-3 mb-3">
		<div className="col-6">
			<select className="form-select form-select-lg" value={selectedTag} onChange={selectionChange}>
				<option value="ceiling-fan">Ceiling Fan</option>
				<option value="standing-fan">Standing Fan</option>
				<option value="wall-fan">Wall Fan</option>
			</select>
		</div>
		</div>
*/}
		<div className="row justify-content-center mt-3 mb-3">
		<div className="col col-lg-6 col-md-12">
		<div className="card-collection">
		{
			items.map((item) => (
				
				<div key={ item.productID} id={ item.productID } className='card m-2' value={ selectedTag }  onClick={ handleCard }>
				<img src={ item.icon } className="card-img-top"/>
				<section className="card-body">
				{/*<p className="card-title">{item.productID}</p>*/}
				<h5>{item.productName}</h5>
				
				</section></div>

				

				))
				
			}
			
			</div>
			</div>
			</div>
			</div>
</>
			)
		}

		export default Items;