import logo from './giphy.gif';
import './App.css';
import Content from "./Components/Content";
import Footer from "./Components/Footer";
import GroupedItems from "./Components/GroupedItems";
import Header from "./Components/Header";
import Items from "./Components/Items";
import Nav from "./Components/Nav";
import NotFound from "./Components/NotFound";
import {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {

  const [selectedTag, setTag] = useState(JSON.parse(localStorage.getItem("selectedTag")) || "ceiling-fan");

  const [items, setItems] = useState([
    {productID: 1, productName: "Panasonic", productTag: "Panasonic", icon: "cjl/panasonic.png"},
    {productID: 2, productName: "KDK", productTag: "kdk", icon: "cjl/KDK.png"},
    {productID: 3, productName: "Sekai", productTag: "sekai", icon: "cjl/sekai.png"},
    {productID: 4, productName: "Broco", productTag: "broco", icon: "cjl/broco.png"},
   

    ]);

  useEffect(() => {
    localStorage.setItem('productList', JSON.stringify(items));


  },[items]);

  useEffect(() => {
    localStorage.setItem('tagList', JSON.stringify(selectedTag));
    

  },[selectedTag]);

  function selectionChange(event){
    console.log(event.target.value);
    setTag(event.target.value);
  }

  function handleCard(event){

    console.log(event.currentTarget.id);
    const transformedItems = items.map((item) => item.productID === parseInt(event.currentTarget.id) ? (item.productTag === selectedTag) ? { ...item, productName:''}:{...item, productTag:selectedTag}:item);   

    setItems(transformedItems);
  }

  return (

    <div className="App">
    <Router>
  
    <Header selectedTag={selectedTag}
      itemCount={items.filter((item) => item.productTag === selectedTag).length}
    />
  
    <Routes> 
      <Route path="/" element={ <Items items={items}
                                selectedTag={selectedTag}
                                selectionChange={selectionChange}
                                handleCard={handleCard}/>
                              }>
      </Route>

      <Route path="/groupeditems" element={ <GroupedItems items={items} selectedTag={selectedTag} setTag={setTag}/>}>

      </Route>

      <Route path="*" element={ <NotFound />}>

      </Route>
    
    </Routes>
    <Footer />
    </Router>
    </div>
  );
}

export default App;
