import  {useState} from 'react';

const GroupedItems = ({items, selectedTag, setTag}) =>{

	const [groupedItems, setGroupedData] = useState(GroupedItems());


	function GroupedItems(){
	}


	return (

		<main className="container">
			<div className="row justify-content-center mt-3 mb-3">
			<div className="col-8">
			<h1 className="mt-4 p5 text-black rounded">Grouped Items</h1>
			</div>
			</div>
		</main>


	)
}

export default GroupedItems;